import Vue from "vue";
import App from "./App.vue";
import "vuetify/dist/vuetify.min.css";
import router from "./router";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";
import VueSweetalert2 from "vue-sweetalert2";
import "firebase/auth";
import VueTour from 'vue-tour';
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);
require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);

import "sweetalert2/dist/sweetalert2.min.css";

const firebaseConfig = {
  apiKey: "AIzaSyBbvSVw4tlgFp1pEQL_6BXFI2dIQ3cDkKQ",
  authDomain: "postzegels-8616d.firebaseapp.com",
  projectId: "postzegels-8616d",
  storageBucket: "postzegels-8616d.appspot.com",
  messagingSenderId: "886869531354",
  appId: "1:886869531354:web:7878d5bca8d66150ab63e3",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars
let app;

firebase.auth().onAuthStateChanged(async function () {
  const user = firebase.auth().currentUser;
  if (user && router.currentRoute.path === '/') {
    router.push('/Dashboard');
  }

  new Vue({
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
  app = true;
});



